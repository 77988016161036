import {BaseService} from "./base.service";

class PaymentsService extends BaseService {
    constructor() {
        super('api/payments');
    }

    clipPayment(params) {
        return this.http.post(`api/clip/transaction`, params).then(response => response.data).catch(err => err.message);
    }
}

export const paymentsService = new PaymentsService();
