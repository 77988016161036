import {initializeAlgoliaSearch} from './algolia';
import VueSweetalert2 from 'vue-sweetalert2';
//---- INICIA CONFIGURACION DE VUE 3 ----//
import './bootstrap';
import {createApp} from 'vue';
import CatalogueComplete from './catalogue-complete.vue';
import 'sweetalert2/dist/sweetalert2.min.css';

//EMPIEZA CONFIGURACION DE VUE 3
const app = createApp({
    // Tus opciones de aplicación aquí
});
app.use(VueSweetalert2);
app.component('catalogue-complete', CatalogueComplete);

app.mount('#app');
//---- INICIA ALGOLIA ----//
document.addEventListener('DOMContentLoaded', function () {
    const searchBoxElement = document.querySelector('#desktop-searchbox');
    if (searchBoxElement) {
        initializeAlgoliaSearch();
    }
});

