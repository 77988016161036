<script setup>
import {ref, computed} from 'vue';

const props = defineProps({
    paginationData: {
        type: Object,
        required: true
    },
});

const emit = defineEmits(['page-changed']);

const visiblePages = computed(() => {
    let pages = [props.paginationData.current_page];
    if (props.paginationData.current_page > 1) {
        pages.unshift(props.paginationData.current_page - 1);
    }
    if (props.paginationData.current_page < props.paginationData.last_page) {
        pages.push(props.paginationData.current_page + 1);
    }
    return pages;
});

const changePage = (page) => {
    if (page > 0 && page <= props.paginationData.last_page) {
        emit('page-changed', page);
    }
};
</script>
<template>
    <div class="products-view__pagination">
        <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ 'disabled': paginationData.current_page === 1 }">
                <a class="page-link page-link--with-arrow" href="#" @click.prevent="changePage(paginationData.current_page - 1)"
                   aria-label="Previous">
                    <i class="fa fa-chevron-left"></i>
                </a>
            </li>
            <li class="page-item" v-for="page in visiblePages" :key="page" :class="{ 'active': page === paginationData.current_page }">
                <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ 'disabled': paginationData.current_page === paginationData.last_page }">
                <a class="page-link page-link--with-arrow" href="#" @click.prevent="changePage(paginationData.current_page + 1)"
                   aria-label="Next">
                    <i class="fa fa-chevron-right"></i>
                </a>
            </li>
        </ul>
    </div>
</template>
