<template>
    <!-- site__body -->
    <div class="site__body">
        <div class="page-header">
            <div class="page-header__container container">
                <div class="page-header__title" style="padding-top: 15px;">
                    <h1>Búsqueda de Productos</h1>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="shop-layout shop-layout--sidebar--start">
                <div class="shop-layout__sidebar">
                    <div class="block block-sidebar block-sidebar--offcanvas--mobile">
                        <div class="block-sidebar__backdrop"></div>
                        <div class="block-sidebar__body">
                            <div class="block-sidebar__header">
                                <div class="block-sidebar__title">Filtros</div>
                                <button class="block-sidebar__close" type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                            <div class="block-sidebar__item">
                                <div class="widget-filters widget widget-filters--offcanvas--mobile" data-collapse
                                     data-collapse-opened-class="filter--opened">
                                    <h4 class="widget-filters__title widget__title">Filtros</h4>
                                    <div class="widget-filters__list">

                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened" data-collapse-item>
                                                <button type="button" class="filter__title" data-collapse-trigger>
                                                    Precio
                                                    <i class="fa fa-chevron-down"></i>
                                                </button>
                                                <div class="range">
                                                    <div v-for="(range, index) in priceRanges" :key="index"
                                                         style="margin-bottom: 10px;">
                                                        <input type="radio" :id="'range' + index"
                                                               :value="JSON.stringify(range)"
                                                               v-model="filters.priceRange"
                                                               style="margin-right: 10px;">
                                                        <label style="font-size: 14px;" :for="'range' + index">
                                                            <span v-if="index < priceRanges.length - 1"> {{
                                                                    formatCurrency(range.min)
                                                                }} a {{ formatCurrency(range.max) }}</span>
                                                            <span v-else-if="index === priceRanges.length - 1">Más de {{
                                                                    formatCurrency(range.max)
                                                                }}</span>
                                                        </label>
                                                    </div>
                                                    <input class="input_min" name="Minimum"
                                                           pattern="^[0-9]+([,.][0-9]+)?$" maxlength="120"
                                                           placeholder="Mínimo" value="">
                                                    <input class="input_max" name="Maximum"
                                                           pattern="^[0-9]+([,.][0-9]+)?$" maxlength="120"
                                                           placeholder="Máximo" value="">
                                                </div>

                                            </div>
                                        </div>
                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened" data-collapse-item>
                                                <button type="button" class="filter__title" data-collapse-trigger>
                                                    Categoría
                                                    <i class="fa fa-chevron-down"></i>
                                                </button>
                                                <div class="filter__body" data-collapse-content>
                                                    <div class="filter__container">
                                                        <div class="filter-list">
                                                            <div class="filter-list__list">

                                                                <!--- ITEM--->
                                                                <label v-for="category in categories" :key="category.id"
                                                                       class="filter-list__item ">
                                                                        <span class="filter-list__input input-check">
                                                                            <span class="input-check__body">
                                                                                <input class="input-check__input"
                                                                                       type="checkbox"
                                                                                       v-model="filters.categories"
                                                                                       :value="category.id">
                                                                                <span class="input-check__box"></span>

                                                                                <i class="text-white input-check__icon fa fa-check"
                                                                                   style="font-size: 12px;"></i>
                                                                            </span>
                                                                        </span>
                                                                    <span class="filter-list__title"> {{
                                                                            category.name
                                                                        }} </span>
                                                                </label>
                                                                <!--- ITEM END--->

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened" data-collapse-item>
                                                <button type="button" class="filter__title" data-collapse-trigger>
                                                    {{ name_spec }}
                                                    <i class="fa fa-chevron-down"></i>
                                                </button>
                                                <div class="filter__body" data-collapse-content>
                                                    <div class="filter__container">
                                                        <div class="filter-list">
                                                            <div class="filter-list__list">
                                                                <!--- ITEM--->
                                                                <label v-for="spec in specifications" :key="spec.id"
                                                                       class="filter-list__item ">
                                                                    <span class="filter-list__input input-check">
                                                                        <span class="input-check__body">
                                                                            <input class="input-check__input"
                                                                                   type="checkbox"
                                                                                   :value="spec.description"
                                                                                   v-model="filters.mando">
                                                                            <span class="input-check__box"></span>
                                                                            <i class="text-white input-check__icon fa fa-check"
                                                                               style="font-size: 12px;"></i>
                                                                        </span>
                                                                    </span>
                                                                    <span class="filter-list__title">{{
                                                                            spec.description
                                                                        }}</span>
                                                                </label>
                                                                <!--- ITEM END--->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="widget-filters__item">
                                            <div class="filter filter--opened" data-collapse-item>
                                                <button type="button" class="filter__title" data-collapse-trigger>
                                                    Marcas
                                                    <i class="fa fa-chevron-down"></i>
                                                </button>
                                                <div class="filter__body" data-collapse-content>
                                                    <div class="filter__container">
                                                        <div class="filter-list">
                                                            <div class="filter-list__list">
                                                                <!--- ITEM--->
                                                                <label class="filter-list__item" v-for="brand in brands"
                                                                       :key="brand.id">
                                                                        <span class="filter-list__input input-check">
                                                                            <span class="input-check__body">
                                                                                <input class="input-check__input"
                                                                                       type="checkbox">
                                                                                <span class="input-check__box"></span>

                                                                                <i class="text-white input-check__icon fa fa-check"
                                                                                   style="font-size: 12px;"></i>
                                                                            </span>
                                                                        </span>
                                                                    <span class="filter-list__title">
                                                                           {{ brand.name }}
                                                                        </span>
                                                                </label>
                                                                <!--- ITEM END--->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-filters__actions d-flex">
                                        <a href="/catalogo-completo">
                                            <button class="btn btn-secondary btn-sm">Reiniciar</button>
                                        </a>
                                        <button class="btn btn-primary btn-sm ml-2" @click="find_products()">Aplicar
                                            filtro
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shop-layout__content">
                    <div class="block">
                        <div class="products-view">
                            <div class="products-view__options">
                                <div class="view-options view-options--offcanvas--mobile">
                                    <div class="view-options__filters-button">
                                        <button type="button" class="filters-button">
                                            <i class="fa fa-th-large"></i>
                                            <span class="filters-button__title">Filtros</span>
                                            <span class="filters-button__counter">3</span>
                                        </button>
                                    </div>
                                    <div class="view-options__layout">
                                        <div class="layout-switcher">
                                            <div class="layout-switcher__list">
                                                <button data-layout="grid-3-sidebar" data-with-features="false"
                                                        title="Cuadrícula" type="button"
                                                        class="layout-switcher__button  layout-switcher__button--active ">
                                                    <i class="fa fa-th-large" style="color: #bbbbbb"></i>
                                                </button>
                                                <button data-layout="grid-3-sidebar" data-with-features="true"
                                                        title="Vista detallada" type="button"
                                                        class="layout-switcher__button ">
                                                    <i class="fa fa-info" style="color: #bbbbbb"></i>
                                                </button>
                                                <button data-layout="list" data-with-features="false" title="Lista"
                                                        type="button" class="layout-switcher__button ">
                                                    <i class="fa fa-list" style="color: #bbbbbb"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="view-options__legend">{{ total_productos }} productos encontrados</div>
                                    <div class="view-options__divider"></div>
                                    <div class="view-options__control">
                                        <label for="">Ordenar por</label>
                                        <div>
                                            <select class="form-control form-control-sm" v-model="sortOrder" name=""
                                                    id="">
                                                <option :value="0">Sin ordenar</option>
                                                <option :value="1">Alfabético</option>
                                                <option :value="2">Precio Menor a Mayor</option>
                                                <option :value="3">Precio Mayor a Menor</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="products-view__list products-list" data-layout="grid-3-sidebar"
                                 data-with-features="false" data-mobile-grid-columns="2">
                                <div class="products-list__body">
                                    <template v-for="product in products">
                                        <!-- ITEM -->
                                        <div class="products-list__item" style="padding-bottom: 3em;">
                                            <div class="product-card product-card--hidden-actions ">
                                                <div class="product-card__image product-image">
                                                    <a :href="'/productos/'+product.friendly_url"
                                                       class="product-image__body">
                                                        <img class="product-image__img"
                                                             :src="product.img_product === 'noimage.jpg' ? '/images/products/'+product.img_product : '/images/products/'+product.id+'/'+product.img_product"
                                                             alt="">
                                                    </a>
                                                </div>
                                                <div class="product-card__info">
                                                    <div class="product-card__name">
                                                        <a :href="'/productos/'+product.friendly_url">
                                                            {{ product.name }}
                                                        </a>
                                                        <template v-for="cat in product.vs_product_categories">
                                                            <p>{{ cat.name }}</p>
                                                        </template>
                                                    </div>
                                                    <ul class="product-card__features-list">
                                                        <template v-for="esp in product.top_five_specifications">
                                                            <li>{{ esp.specification.name }} {{ esp.description }}</li>
                                                        </template>
                                                        <li>
                                                            <a :href="'productos/'+product.friendly_url">Leer más...</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="product-card__actions">
                                                    <div class="product-card__availability">
                                                        Disponibilidad: <span
                                                        class="text-success">Entrega Inmediata</span>
                                                    </div>
                                                    <div class="product-card__prices">
                                                        {{ formatCurrency(product.price_public) }}
                                                    </div>
                                                    <div class="product-card__buttons">
                                                        <button
                                                            @click="addToCartWrapper(product)"
                                                            class="btn btn-primary product-card__addtocart"
                                                            type="button">Añadir al Carrito
                                                        </button>
                                                        <a :href="'/productos/'+product.friendly_url">
                                                            <button class="btn btn-primary product-card__addtocart"
                                                                    type="button">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </a>
                                                        <button
                                                            @click="addToCartWrapper(product)"
                                                            class="btn btn-secondary product-card__addtocart product-card__addtocart--list"
                                                            type="button">Añadir al Carrito
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END ITEM -->
                                    </template>
                                </div>
                            </div>
                            <pagination
                                :pagination-data="pagination_data"
                                @page-changed="changePage"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- site__body / end -->
</template>

<script setup>
import axios from "axios";
import {computed, onMounted, ref, watch} from 'vue';
import pagination from './Pagination.vue';
import {addToCart} from './utils.js';
import {productsService} from "./services/Products.service";

const selectedCategory = ref(null);

const props = defineProps({
    minPrice: {
        type: String,
        required: true
    },
    maxPrice: {
        type: String,
        required: true
    },
    categories: {
        type: Array,
        required: true
    },
    specifications: {
        type: Array,
        required: true
    },
    slug: {
        type: String,
        required: false
    },
    brands: {
        type: Array,
        required: false
    },
    name_spec: {
        type: String,
        required: false
    }
});

const filters = ref({
    priceRange: {},
    categories: [],
    mando: []
});

const priceRange = ref({});

const check_category = ref([]);

let sortOrder = ref(0); // el valor del select

let products = ref([]); // los productos que se mostrarán

let total_productos = ref(0); // el total de productos que se mostrarán

const pagination_data = ref({
    current_page: 1,
    data: [],
    first_page_url: '',
    from: 0,
    last_page: 0,
    last_page_url: '',
    links: [],
    next_page_url: '',
    path: '',
    per_page: 0,
    prev_page_url: '',
    to: 0,
    total: 0,
})

//METOODS COMPUTADOS
const priceRanges = computed(() => {
    const totalRange = cleanAndConvertPrice(props.maxPrice) - cleanAndConvertPrice(props.minPrice);
    const rangeSize = totalRange / 4;
    return Array.from({length: 4}, (_, i) => {
        return {
            min: cleanAndConvertPrice(props.minPrice) + (i * rangeSize),
            max: cleanAndConvertPrice(props.minPrice) + ((i + 1) * rangeSize)
        };
    });
});

function cleanAndConvertPrice(priceString) {
    // Elimina los puntos del string y conviértelo a número
    return parseFloat(priceString);
}

let sortedProducts = computed(() => {
    //console.log(sortOrder.value)
    switch (sortOrder.value) {
        case 1:
            return [...products.value].sort((a, b) => a.name.localeCompare(b.name));
        case 2:
            return [...products.value].sort((a, b) => a.price_public - b.price_public);
        case 3:
            return [...products.value].sort((a, b) => b.price_public - a.price_public);
        default:
            // Sin ordenar, devolvemos los productos tal como están
            return products.value;
    }
});

const selectedRange = computed(() => {
    return JSON.parse(priceRange.value);
});

//METODOS NORMALES
const addToCartWrapper = (product) => {
    let category = getLastCategory(product);
    if (category) {
        category = category.name;
    } else {
        category = 'Sin categoría';
    }
    addToCart(product.id, product.price_public, product.sku, product.name, category, product.type, product.img_product, product.friendly_url);
}

const changePage = async (page) => {
    pagination_data.value.current_page = page;

    // Aquí debes hacer la llamada a la API para obtener los productos de la nueva página
    try {
        const response = await axios.post('/search-product', {
            ...filters.value,
            page: page
        }).then(response => response.data).catch(err => err.message);
        products.value = response.products.data;
        pagination_data.value = response.products;
        total_productos.value = response.total;
    } catch (error) {
        console.error(error);
    }

};

const find_products = async () => {
    try {
        sortOrder.value = 0;
        const response = await axios.post('/search-product', filters.value).then(response => response.data).catch(err => err.message);
        //console.log('busqueda=', response);
        products.value = response.products.data;
        pagination_data.value = response.products;
        total_productos.value = response.total;
    } catch (error) {
        //console.error(error);
    }

};

const getLastCategory = (product) => {

    if (product.vs_product_categories.length > 0) {
        return product.vs_product_categories[product.vs_product_categories.length - 1];
    }
    return null;
}

// Añade un watcher para sortOrder
watch(sortOrder, () => {
    products.value = sortedProducts.value;
});

const formatCurrency = (price) => {
    return Number(price).toLocaleString('en-US', {
        style: 'currency', currency: 'USD'
    })
}

onMounted(() => {

    //console.log('props', props);

    if (filters.value && filters.value.categories) {
        for (let category of props.categories) {
            if (category.slug === props.slug) {
                // Si coinciden, agrega el id de la categoría a filters.categories
                filters.value.categories.push(category.id);
            }
        }
    }

    find_products();
});

// Asegúrate de devolver cualquier cosa que quieras usar en tu template
</script>
<style scoped>
.input_min, .input_max {
    border: 1px solid #f0f0f0;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    width: 45%;
}

.input_min::after {
    content: " - ";
    display: block;
    font-size: 12px;
    color: #000;
    margin-top: 5px;

}

.input_max {
    margin-left: 10px;
}
</style>
