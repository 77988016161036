import {BaseService} from "./base.service";

class CitiesService extends BaseService {

    constructor() {
        super('')
    }

    getCityByState(state) {
        return this.http.get(`get-city-by-state/${state}`).then(response => response.data).catch(err => err.message);;
    }

}

export const citiesService = new CitiesService();
