import {BaseService} from "./base.service";

class ProductsService extends BaseService {

    constructor() {
        super('api/products')
    }

    getProduct(id) {
        return this.http.get('api/product/' + id).then(response => response.data).catch(err => err.message);
    }

    checkout() {
        return this.http.get('api/checkout').then(response => response.data).catch(err => err.message);

    }

    visibleProduct(id, status) {
        return this.http.get('api/visible-product/' + id + '/' + status).then(response => response.data).catch(err => err.message);
    }

    searchProducts(search) {
        return this.http.post('/search-product', {search}).then(response => response.data).catch(err => err.message);
    }


}

export const productsService = new ProductsService();
