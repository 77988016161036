import {BaseService} from "./base.service";

class AccountService extends BaseService {

    constructor() {
        super('')
    }

    updateOrCreateTaxesAccount(id, params, options = {}) {
        let config = {};
        let {
            isForm = false,
        } = options;

        if (isForm === true) {
            params.append("_method", 'PATCH');
            config = {
                method: "POST",
                headers: {'Content-Type': 'multipart/form-data', 'enctype': 'multipart/form-data'}
            }
            return this.http({
                method: "post",
                url: `create-or-update-taxes-account/${id}`,
                data: params,
                headers: config.headers
            }).then(response => response.data).catch(err => err.message);
        } else {
            return this.http.patch(`create-or-update-taxes-account/${id}`, params, config).then(response => response.data).catch(err => err.message);
        }
    }

    addAddress(id, params) {
        return this.http.post(`add-address-account/${id}`, params).then(response => response.data).catch(err => err.message);
    }

    updateInfoAccount( params) {
        return this.http.post(`update-info-account`, params).then(response => response.data).catch(err => err.message);
    }

    updateAddress(id, params) {
        return this.http.put(`update-info-account/${id}`, params).then(response => response.data).catch(err => err.message);
    }

    getAddressByClient(id) {
        return this.http.get(`api/get-address-by-client/${id}`).then(response => response.data).catch(err => err.message);
    }

}

export const accountService = new AccountService();
