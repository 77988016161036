import {BaseService} from "./base.service";

class SettingsService extends BaseService {
    constructor() {
        super('api/settings');
    }

    updateMultiple(settings) {
        const promises = Object.entries(settings).map(([key, value]) => {
            return this.updateOne(key, {value});
        });

        return Promise.all(promises);
    }

    getLatLng() {
        return this.http.get(`/get-lat-lng`).then(response => response.data).catch(err => err.message);
    }
}

export const settingsService = new SettingsService();
